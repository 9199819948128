<template>
    <b-row>
        <b-col sm="6">
            <b-button v-if="!editOn" variant="outline-success" size="sm" @click="checkDeliveryEdit('SET_EDITABLE_DELIVERY')">{{ $t('edit') }}</b-button>
            <div class="form-group">
                <label for="delivery_method">{{ $t('delivery_method') }}<span class="text-danger">*</span></label>
                <select
                name="delivery_method"
                class="form-control"
                v-model="orderData.delivery.delivery_method"
                @change="changeDeliveryMethod" :disabled="!editOn || printed">
                    <option v-for="(item, index) in deliveries" :key="index" :value="item.slug">{{ item.name }}</option>
                </select>
            </div>
            <div class="form-group" v-if="orderData.delivery.delivery_method === 'nova-posta'">
                <label for="delivery_type">{{ $t('delivery_type') }}<span class="text-danger">*</span></label>
                <select
                @change="clearDeliveryData"
                v-model="orderData.delivery.delivery_type"
                name="delivery_type"
                class="form-control" :disabled="!editOn">
                    <option v-for="(item, index) in deliveryTypes[orderData.delivery.delivery_method]" :value="item.id" :key="index">{{ item.name }}</option>
                </select>
            </div>
            <!-- UKR-poshta -->
            <UkrDeliveryType />
        </b-col>
        <b-col sm="6" v-if="orderData.delivery.delivery_method === 'nova-posta'">
            <div class="form-group">
                <label for="city">{{ $t('city_recipient') }}</label>
                <model-list-select
                    :list="cities"
                    option-value="ref"
                    option-text="name"
                    v-model="orderData.delivery.city_recipient"
                    :placeholder="$t('nothing_selected')"
                    @searchchange="searchCity" :is-disabled="!editOn"></model-list-select>
            </div>
            <div class="form-group" v-if="orderData.delivery.delivery_type === 'WarehouseWarehouse'">
                <label for="warehouse">{{ $t('warehouse_or_street') }}</label>
                <!--<select v-if="warehouses" v-model="orderData.delivery.warehouse" class="form-control">
                        <option v-for="(item, idx) in warehouses" :key="idx">{{ item.Description }}</option>
                </select>-->
                <list-select
                    :list="warehouses"
                    v-model="orderData.delivery.warehouse_ref"
                    option-value="Ref"
                    option-text="Description"
                    :selected-item="{ Ref: orderData.delivery.warehouse_ref, Description: orderData.delivery.warehouse }"
                    @select="onSelectWarehouse"
                    :placeholder="$t('nothing_selected')" :is-disabled="!editOn"></list-select>
            </div>
            <div class="form-group" v-if="orderData.delivery.delivery_type === 'WarehouseDoors'">
                <label for="address">Вулиця</label>
                <model-list-select
                    :list="streets"
                    option-value="ref"
                    option-text="name"
                    v-model="orderData.delivery.street_ref"
                    :selected-item="{ ref: orderData.delivery.street_ref, name: orderData.delivery.street }"
                    :placeholder="$t('nothing_selected')"
                    @searchchange="searchStreet"
                    :is-disabled="!editOn"></model-list-select>
            </div>
            <b-input-group v-if="orderData.delivery.delivery_type === 'WarehouseDoors'">
                <b-form-input placeholder="№ дому" v-model="orderData.delivery.house_number" :disabled="!editOn"></b-form-input>
                <b-form-input placeholder="Етаж" v-model="orderData.delivery.flat" :disabled="!editOn"></b-form-input>
                <b-form-input placeholder="№ квартири" v-model="orderData.delivery.apartment_number" :disabled="!editOn"></b-form-input>
            </b-input-group>
            <b-form-group :label="$t('recipient')">
                <b-form-radio v-model="orderData.delivery.entity" name="entity" value="individual" :disabled="!editOn">{{ $t('fiz_lico') }}</b-form-radio>
                <b-form-radio v-model="orderData.delivery.entity" name="entity" value="legal" :disabled="!editOn">{{ $t('ur_lico') }}</b-form-radio>
            </b-form-group>
        </b-col>
        <UkrPostaTarget v-else-if="orderData.delivery.delivery_method === 'ukr-posta'"/>
        <b-col sm="6" v-else-if="orderData.delivery.delivery_method === 'samoviviz'">
            <div class="form-group">
                <label for="city">{{ $t('area') }}/{{ $t('city') }}</label>
                <input type="text" class="form-control" v-model="orderData.delivery.city_name">
            </div>
            <b-form-group :label="$t('recipient')">
                <b-form-radio v-model="orderData.delivery.entity" name="entity" value="individual">{{ $t('fiz_lico') }}</b-form-radio>
                <b-form-radio v-model="orderData.delivery.entity" name="entity" value="legal">{{ $t('ur_lico') }}</b-form-radio>
            </b-form-group>
        </b-col>
        <b-col sm="6" v-else>
            <div class="form-group">
                <label for="city">{{ $t('area') }}/{{ $t('city') }}</label>
                <input type="text" class="form-control" v-model="orderData.delivery.city_name">
            </div>
            <div class="form-group">
                <label for="zip_code">{{ $t('index') }}</label>
                <input type="text" name="zip_code" class="form-control" v-model="orderData.delivery.zip_code">
            </div>
            <b-form-group :label="$t('recipient')">
                <b-form-radio v-model="orderData.delivery.entity" name="entity" value="individual">{{ $t('fiz_lico') }}</b-form-radio>
                <b-form-radio v-model="orderData.delivery.entity" name="entity" value="legal">{{ $t('ur_lico') }}</b-form-radio>
            </b-form-group>
        </b-col>
        <b-col v-if="editOn" sm="12" class="mb-2">
            <b-button variant="success" @click="updateDelivery">{{ $t('save') }}</b-button>
            <hr>
        </b-col>
    </b-row>
</template>
<script>
import { mapGetters, mapActions } from 'vuex'
import axios from 'axios'
import { ModelListSelect, ListSelect } from 'vue-search-select'
import UkrPostaTarget from './ukrPoshta/target'
import UkrDeliveryType from './ukrPoshta/deliveryType'
export default {
    data: () => ({
        selectedCity: '',
        cities: [],
        searchCityText: '',
        warehouses: [],
        searchWarehouseText: '',
        streets: [],
        componentKey: 0
    }),
    components: {
        ModelListSelect,
        ListSelect,
        UkrPostaTarget,
        UkrDeliveryType
    },
    watch: {
        'orderData.delivery.city_recipient': function () {
            this.searchWarehouses()
        },
        'orderData.delivery.street_ref': function () {
            this.getStreetByKey()
        }
    },
    mounted () {
        setTimeout(() => {
            this.initOrderDelivery()
        }, 2000)
    },
    computed: {
        ...mapGetters({
            deliveries: 'order/deliveries',
            deliveryTypes: 'order/deliveryTypes',
            orderData: 'order/order',
            editOn: 'order/editDeliveryData',
            printed: 'order/printed'
        })
    },
    methods: {
        ...mapActions({
            changeDeliveryType: 'order/changeDeliveryType',
            setDeliveryData: 'order/setDeliveryData',
            updateStatuses: 'order/updateStatuses',
            editCheck: 'order/editCheck',
            updateOrder: 'order/updateOrder',
            checkDeliveryRedirecting: 'order/checkDeliveryRedirecting',
            deliveryRedirecting: 'order/deliveryRedirecting'
        }),
        checkDeliveryEdit (type) {
            const id = this.$route.params.id
            if (this.printed) {
                this.$root.$refs.loading.start()
                this.checkDeliveryRedirecting(id)
                this.$root.$refs.loading.finish()
            } else {
                this.editCheck(type)
            }
        },
        updateDelivery () {
            const id = this.$route.params.id
            if (this.printed) {
                this.deliveryRedirecting(id)
            } else {
                this.updateOrder({ id: id, type: 'EDIT_DELIVERY' })
            }
        },
        clearDeliveryData () {
            this.searchCity(this.orderData.delivery.city_recipient_name)
            if (this.orderData.delivery.delivery_type === 'WarehouseWarehouse') {
                this.searchWarehouses()
                // clear address delivery info
                this.orderData.delivery.street = null
                this.orderData.delivery.street_ref = null
                this.orderData.delivery.house_number = null
                this.orderData.delivery.apartment_number = null
            } else if (this.orderData.delivery.delivery_type === 'WarehouseDoors') {
                // clear warehouse delivery info
                this.orderData.delivery.warehouse = null
                this.orderData.delivery.warehouse_ref = null
            }
        },
        initOrderDelivery () {
            // search city and warehouse
            this.searchCity(this.orderData.delivery.city_recipient_name)
            this.searchWarehouse(this.orderData.delivery.warehouse)
            this.searchStreet(this.orderData.delivery.street)
        },
        async searchCity (value) {
            if ((value || []).length > 1) {
                
                this.searchCityText = value
                const apiUrl = process.env.VUE_APP_ENDPOINT_V1
                await axios.post(apiUrl + 'city/search', { key: value }).then(resp => {
                    this.cities = resp.data.data
                    this.warehouses = []
                })
            }
        },
        async searchWarehouses () {
            this.$root.$refs.loading.start()
            if (this.orderData.delivery.city_recipient) {
                const apiUrl = process.env.VUE_APP_ENDPOINT_V1
                const { data } = await axios.post(apiUrl + 'city/warehouse/cityref', { city: this.orderData.delivery.city_recipient, key: '' })
                this.warehouses = data.data
            }
            this.$root.$refs.loading.finish()
        },
        async searchWarehouse (value) {
            this.$root.$refs.loading.start()
            if (this.orderData.delivery.city_recipient) {
                if ((value || []).length > 0) {
                    const apiUrl = process.env.VUE_APP_ENDPOINT_V1
                    const { data } = await axios.post(apiUrl + 'city/warehouse/cityref', { city: this.orderData.delivery.city_recipient, key: value })
                    this.warehouses = data.data
                }
            }
            this.$root.$refs.loading.finish()
        },
        async searchStreet (value) {
            this.$root.$refs.loading.start()
            if (this.orderData.delivery.city_recipient) {
                if ((value || []).length > 1) {
                    value = value.replace('вул.', '').replace('просп.', '')
                    const apiUrl = process.env.VUE_APP_ENDPOINT_V1
                    const { data } = await axios.post(apiUrl + 'city/street', { city: this.orderData.delivery.city_recipient, key: value })
                    /*
                    if (data && !data.success) {
                        Swal.fire({
                            icon: 'warning',
                            title: this.$t('error'),
                            text: data.msg,
                            reverseButtons: true,
                            confirmButtonText: this.$t('ok'),
                            cancelButtonText: this.$t('cancel')
                        })
                    } else {
                    */
                        this.streets = data
                        this.orderData.delivery.street = value
                    // }
                }
            }
            this.$root.$refs.loading.finish()
        },
        async getStreetByKey () {
            this.$root.$refs.loading.start()
            if (this.orderData.delivery.street_ref) {
                const street = this.streets.find(s => s.ref === this.orderData.delivery.street_ref)
                this.orderData.delivery.street = street.name
            }
            this.$root.$refs.loading.finish()
        },
        async changeDeliveryMethod () {
            const apiUrl = process.env.VUE_APP_ENDPOINT_V1
            if (this.orderData.delivery.delivery_method === 'nova-posta') {
                const citySender = this.deliveries.find(d => d.slug === this.orderData.delivery.delivery_method)
                if (this.orderData.delivery.city_recipient) {
                    this.$root.$refs.loading.start()
                    const { data } = await axios.post(apiUrl + 'deliveries/price', {
                        citySender: citySender.city_ref,
                        cityRecipient: this.orderData.delivery.city_recipient,
                        deliveryType: this.orderData.delivery.delivery_type,
                        deliveryMethod: this.orderData.delivery.delivery_method
                    })
                    this.setDeliveryData(data)
                    this.$root.$refs.loading.finish()
                }
            } else {
                this.setDeliveryData({ deliveryCost: 0, deliveryDate: null })
            }
            this.updateStatuses()
        },
        onSelectWarehouse (item) {
            this.orderData.delivery.warehouse_ref = item.Ref
            this.orderData.delivery.warehouse = item.Description
        }
    }
}
</script>
